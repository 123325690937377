import styled from '@emotion/styled'

export const ArticleTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 95px;

  > a {
    padding-top: 0px;
    padding-bottom: 60px !important;
    transition: transform 400ms ease;
    transform: scale(1);
    @media (min-width: ${props => props.theme.breakpoints.m}) {
      :hover {
        transform: scale(0.98);
      }
    }
  }
`

export const ArticleTilesCondensedContainer = styled.div`
  max-width: 1000px;
  width: 75%;
  margin-top: 60px;

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
    display: block;
  }

  > a {
    padding: 0 0 30px 0 !important;
    max-width: 100%;
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoints.m}) {
      transition: transform 400ms ease;
      transform: scale(1);

      :hover {
        transform: scale(0.98);
      }
    }
  }
`

export const LoadMore = styled.span`
  display: block;
  font-size: ${props => props.theme.fonts.xl_small};
  color: ${props => props.theme.colors.primarygreen};
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  margin-top: 50px;
`
