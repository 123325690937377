import React, { useContext, useEffect, useState } from 'react'
import FeaturedArticle from 'components/FeaturedArticle'
import Container from 'components/Container'
import Wrapper from 'components/Wrapper'
import GlobalContext from 'context/GlobalStateProvider'
import CategoryFilter from '../../CategoryFilter'
import PreFooter from 'components/PreFooter'
import ArticleTile from 'components/ArticleTile'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
  ArticleTilesContainer,
  ArticleTilesCondensedContainer,
  LoadMore,
} from './styles'

const BlogLayout = props => {
  const {
    data: {
      prismicArticleGlobalContent: {
        data: { estimated_read_time },
      },
      allPrismicArticle: { edges: articles },
      prismicBlogGlobalContent: {
        data: {
          all,
          read,
          load_more,
          banner: {
            document: { data: bannerQuery },
          },
        },
      },
    },
  } = props
  const globalContext = useContext(GlobalContext)
  const [category, setCategory] = useState(all?.text?.toLowerCase())
  const [projects, setProjects] = useState(null)
  const [allProjects, setAllProjects] = useState(null)
  const [categories, setCategories] = useState(null)
  const [visibleArticles, setVisiblesArticles] = useState(9)
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('960'))


  useEffect(() => {
    setCategories([
      ...new Set(
        articles
          ?.map(({ node }) => {
            const tags = node?.data?.tags?.text.includes(',')
              ? node.data.tags.text.split(',')
              : node?.data?.tags?.text.trim()

            return typeof tags !== 'string' ? tags.map(t => t.trim()) : tags
          })
          .flat()
      ),
    ])
    globalContext.setLightColorTheme(true)
  }, [])

  useEffect(() => {
    if (
      category?.toLowerCase() !== all?.text?.toLowerCase() &&
      category !== null
    ) {
      const articlesFiltered = articles?.filter(({ node }) => {
        const tags = node?.data?.tags?.text.includes(',')
          ? node.data.tags.text.split(',')
          : node?.data?.tags?.text.trim()

        return tags.includes(category)
      })

      setAllProjects(articlesFiltered)
      setProjects(articlesFiltered.slice(0, visibleArticles))
    } else {
      setAllProjects(articles)
      setProjects(articles.slice(0, visibleArticles))
    }
  }, [category, articles, visibleArticles])

  const filterProps = {
    allLabel: all?.text,
    category,
    setCategory,
    categories,
  }

  const preFooterProps = {
    query: {
      body: bannerQuery?.body,
      cta: bannerQuery?.cta,
      image: bannerQuery?.image,
    },
  }

  const firstArticle = projects?.slice().shift()
  const secondToFifthArticles = projects?.slice(1, 6)
  const restOfArticles = projects?.slice(6, projects?.length)

  const loadMoreClickHandler = () => {
    setVisiblesArticles(visibleArticles + 1)
  }

  return (
    <>
      <Wrapper
        style={{
          opacity: globalContext?.openMenu ? 0 : 1,
          position: globalContext?.openMenu ? 'relative' : 'initial',
          background: '#ffffff',
          top: globalContext?.openMenu ? '152px' : 0,
        }}
      >
        <Container>
          <FeaturedArticle
            read={read}
            estimated_read_time={estimated_read_time}
            uid={firstArticle?.node?.uid}
            node={firstArticle?.node}
            date={firstArticle?.node?.data.date}
          />
          <CategoryFilter
            dropdownFilterStyle={{
              marginBottom: -40,
              marginTop: 40,
              marginLeft: 0,
            }}
            style={{ paddingLeft: 0 }}
            {...filterProps}
          />
        </Container>
        {secondToFifthArticles?.length > 0 ? (
          <Container>
            <ArticleTilesContainer>
              {secondToFifthArticles?.map(({ node }) => (
                <ArticleTile
                  estimated_read_time={estimated_read_time}
                  node={node}
                />
              ))}
            </ArticleTilesContainer>
          </Container>
        ) : (
          <></>
        )}
        <Container style={{ marginBottom: isDesktop ? 120 : 80 }}>
          <ArticleTilesCondensedContainer>
            {restOfArticles?.map(({ node }) => (
              <ArticleTile
                condensed={true}
                estimated_read_time={estimated_read_time}
                node={node}
              />
            ))}
          </ArticleTilesCondensedContainer>
          {load_more?.text && visibleArticles < allProjects?.length ? (
            <LoadMore onClick={loadMoreClickHandler} role="button">
              {load_more?.text}
            </LoadMore>
          ) : (
            <></>
          )}
        </Container>
        <PreFooter {...preFooterProps} />
      </Wrapper>
    </>
  )
}

export default BlogLayout
