import React, { useContext, useEffect } from 'react'
import LocaleContext from 'context/LocaleProvider'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import BlogLayout from 'components/PagesLayout/Blog'

const Blog = props => {
  const lang = useContext(LocaleContext)

  useEffect(() => {
    lang?.setPageDetails({
      uid: props?.pageContext?.alternativeLanguageUid,
      type: 'page',
    })
  }, [])

  return <BlogLayout {...props} />
}

export default withPreview(Blog)

export const blogQuery = graphql`
  query blog($locale: String!) {
    allPrismicArticle(
      filter: { lang: { eq: $locale } }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          lang
          uid
          alternate_languages {
            uid
          }
          data {
            date(formatString: "MMM. DD, YYYY")
            page_type
            tags {
              text
            }
            title {
              text
            }
            excerpt {
              text
            }
            content {
              html
            }
            main_image {
              alt
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicArticleGlobalContent(lang: { eq: $locale }) {
      data {
        estimated_read_time {
          text
        }
      }
    }
    prismicBlogGlobalContent(lang: { eq: $locale }) {
      data {
        all {
          text
        }
        read {
          text
        }
        load_more {
          text
        }
        banner {
          document {
            ... on PrismicPrefooter {
              id
              data {
                body {
                  text
                }
                cta {
                  page_type
                  cta_style
                  cta_link {
                    uid
                  }
                  cta_label {
                    text
                  }
                }
                title {
                  text
                }
                image {
                  localFile {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seo {
          id
          document {
            __typename
            ... on PrismicSeo {
              id
              data {
                breadcrumb_title {
                  text
                }
                internal_title {
                  text
                }
                open_graph_description {
                  text
                }
                open_graph_image {
                  alt
                  localFile {
                    url
                  }
                }
                open_graph_title {
                  text
                }
                seo_description {
                  text
                }
                seo_title {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`
